import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import slg1 from '../images/bg/jalkahoito-slg.jpg';
import slg2 from '../images/bg/jh-slg2.jpg';
import hieronta1 from '../images/hieronta1.jpeg';
import hieronta2 from '../images/hieronta2.jpeg';
import hieronta3 from '../images/hieronta3.jpeg';
import Jalkahoitaja from '../images/avatar/jh-hoitaja.jpg';

const Footcare = () => {
    return (
        <Layout>
            <section className="hero" data-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Jalkahoito palvelut</h1>
                        </div>
                    </div>
                </div>
                <div className="herobg heroimage4"></div>
            </section>
            <section className="py-3">
                <div className="container">
                    <div className="row justify-content-center sticky-container">
                        <div className="col-md-10">
                            <h2 className="mb-35 text-center">
                                Asiakas- ja ihmislähtöistä jalkahoitoa
                            </h2>
                            <p>
                                Hoidan jalkavaivoja ja autan ehkäisemään
                                jalkasairauksien syntymistä.
                            </p>

                            <h3 className="mb-20 ls2">
                                Jalkahoito alkaen{' '}
                                <strong className="crimson">45€</strong>
                            </h3>
                            <h4 className="mb-35 ls2">Palvelut ja hinnasto</h4>
                            <div className="post-author author-content">
                                <h5 className=" mb-15 ls2">
                                    Kynsien leikkaus &#40;noin 60 min&#41; 45€
                                </h5>
                                <p>
                                    – Sisältää jalkojen tutkimuksen, kynsien
                                    leikkauksen, rasvahieronnan sekä omahoidon
                                    ohjauksen.
                                </p>
                            </div>
                            <div className="post-author author-content">
                                <h5 className="mb-15 ls2">
                                    Jalkojen perushoito &#40;noin 60 min&#41;
                                    55€
                                </h5>
                                <p>
                                    – Sisältää jalkojen tutkimuksen, kynsien
                                    lyhennyksen ja ohennuksen &#40;ei
                                    kynsilakkaa&#41;, kovettumien poiston ja
                                    känsienpoiston, rasvahieronnan sekä
                                    omahoidon ohjausta.
                                </p>
                            </div>
                            <div className="post-author author-content">
                                <h5 className="mb-15 ls2">
                                    Jalkojen vaativampihoito &#40;noin 60-90
                                    min&#41; 60€
                                </h5>
                                <p>
                                    – Sisältää jalkojen tutkimuksen, kynsien
                                    lyhennyksen{' '}
                                    <strong>(myös paksuuntuneet kynnet)</strong>{' '}
                                    ja ohennuksen &#40;ei kynsilakkaa&#41;,
                                    kovettumien poiston ja känsienpoiston,
                                    rasvahieronnan sekä omahoidon ohjausta.
                                </p>
                            </div>
                            <div className="post-author author-content">
                                <h5 className=" mb-15 ls2">
                                    Jalkojen pidempihoito &#40;noin 90 min&#41;
                                    70€
                                </h5>
                                <p>
                                    – Sisältää jalkojen tutkimuksen
                                    &#40;sairauksia esim. diabetes, reuma,
                                    psoriaasi&#41;, kynsi- ja iho-ongelmien
                                    hoidon&#40;esim. ihon sisään kasvanut kynsi,
                                    paksuuntunut kynsi&#41;, ei sisällä
                                    kynsilakkaa. Lisäksi kovettumien ja känsien
                                    poiston, rasvauksen sekä omahoidon ohjeet.
                                </p>
                            </div>
                            <h4>Hieronta</h4>
                            <div className="post-author author-content">
                                <h5 className=" mb-15 ls2">
                                    Ylä- ja alavartalon hieronta 45 min, hinta
                                    40€
                                </h5>
                            </div>
                            <div className="post-author author-content">
                                <h5 className=" mb-15 ls2">
                                    Koko vartalon hieronta 70 min, hinta 60€
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="proj-container">
                    <div className="container my-3">
                        <div className="row justify-content-md-center">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-12 my-3 text-center">
                                        <h3>Hierota työt</h3>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="box-im">
                                                    <div
                                                        className="image-zoom"
                                                        data-dsn-grid="parallax"
                                                        data-dsn-grid-move="20"
                                                    >
                                                        <Link
                                                            className="single-image"
                                                            to="/jalkahoito"
                                                        ></Link>
                                                        <img
                                                            src={hieronta1}
                                                            alt="Salon Looking Good - Hieronta"
                                                        />
                                                    </div>
                                                    <div className="caption">
                                                        <span>
                                                            Salon Looking Good,
                                                            2024
                                                        </span>{' '}
                                                        - Hieronta
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="box-im">
                                                    <div
                                                        className="image-zoom"
                                                        data-dsn-grid="parallax"
                                                        data-dsn-grid-move="20"
                                                    >
                                                        <Link
                                                            className="single-image"
                                                            to="/jalkahoito"
                                                        ></Link>
                                                        <img
                                                            src={hieronta2}
                                                            alt="Salon Looking Good - Hieronta"
                                                        />
                                                    </div>
                                                    <div className="caption">
                                                        <span>
                                                            Salon Looking Good,
                                                            2024
                                                        </span>{' '}
                                                        - Hieronta
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="box-im">
                                                    <div
                                                        className="image-zoom"
                                                        data-dsn-grid="parallax"
                                                        data-dsn-grid-move="20"
                                                    >
                                                        <Link
                                                            className="single-image"
                                                            to="/jalkahoito"
                                                        ></Link>
                                                        <img
                                                            src={hieronta3}
                                                            alt="Salon Looking Good - Hieronta"
                                                        />
                                                    </div>
                                                    <div className="caption">
                                                        <span>
                                                            Salon Looking Good,
                                                            2024
                                                        </span>{' '}
                                                        - Hieronta
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 my-3">
                                        <h3 className="text-center">
                                            Jalkahoito työt
                                        </h3>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/jalkahoito"
                                                ></Link>
                                                <img
                                                    src={slg1}
                                                    alt="Salon Looking Good - Jalkahoito"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Jalkahoito
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/jalkahoito"
                                                ></Link>
                                                <img
                                                    src={Jalkahoitaja}
                                                    alt="Salon Looking Good - Jalkahoitaja"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Jalkahoitaja Inna
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/parturi-kampaamo"
                                                ></Link>
                                                <img
                                                    src={slg2}
                                                    alt="Salon Looking Good - Parturi palvelut"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Jalkahoito
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Footcare;
